import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Section from "./Core/Section";
import Container from "./Core/Container";
import breakpoints from "../styles/breakpoints";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Background = styled.div`
  background: var(--white);
`;

const Content = styled.div`
  padding: 3.125rem 1.25rem 0;
  
  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }
  
  @media only screen and (min-width: ${breakpoints.md}) {
    padding: 3.125rem 1.25rem;
  }
`;

const SecondContent = styled(Content)`
  padding: 0 1.25rem 3.125rem;

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }
  
  @media only screen and (min-width: ${breakpoints.md}) {
    padding: 3.125rem 1.25rem;
  }
`;

const QuickNavigation = (props) => {

      
  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark
        return <AnchorLink to={href}>{children}</AnchorLink>
      },
      id: ({mark, children}) => {
        const { id } = mark
        return <span id={id}>{children}</span>
      },
    }
  }

    return (
      <Section>
        <Container>
          <Background>
            <Row>
              <Col md={6}>
                <Content>
                  <BlockContent blocks={props.leftColumn._rawCopy} serializers={serializers}/>
                </Content>
              </Col>
              <Col md={6}>
                <SecondContent>
                  <BlockContent blocks={props.rightColumn._rawCopy} serializers={serializers}/>
                </SecondContent>
              </Col>
            </Row>
          </Background>
        </Container>
      </Section>
    );
};

export default QuickNavigation;